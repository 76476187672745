import React, { Component } from 'react';
import { Route } from 'react-router';
import { SurveyResponse } from './components/SurveyResponse';

export default class App extends Component {
    render() {
        return (
            <Route path='/*' component={SurveyResponse} />
        );
    }
}