import React, { Component } from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async";

import './surveyResponse.css'
import './starrating.css'
import './emoji.css'
import './spinner.css'
import './radiobutton.css'
import './checkbox.css'
import './numberrating.css'
import './message.css'

export class SurveyResponse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            surveyResponse: {},
            sharedLinkId: '',
            loading: true,
            answersBySurveyor: [],
            requiredQuestionIds: [],
            activateSendButton: false,
            activateCloseMessage: false,
            authorizationValue: '',
            httpStatusCode: 500,
            css: ''
        };
    }

    componentDidMount() {
        this.populateSurveyResponse();
    }

    populateSurveyResponse(cultureInfoCode = null) {
        let incommingPath = window.location.pathname;
        if (incommingPath && (incommingPath.length > 15)) {
            let url = 'https://yellowfeedbackapi.autoproof.nl/'
            //let url = 'https://localhost:44350/';
            const path = 'survey/response';
            let newUrl = url + path + incommingPath;

            //getting cultureInfoCode from query
            const urlParams = new URLSearchParams(window.location.search);
            const selectedLanguage = urlParams.get('language');
            if (selectedLanguage) {
                cultureInfoCode = selectedLanguage;
            }

            //try get cultureInfoCode from cookie
            let cookieValue = this._getCookie(this.state.sharedLinkId);
            if (cookieValue) {
                cultureInfoCode = cookieValue;
            }

            if (cultureInfoCode) {
                newUrl += `?language=${cultureInfoCode}`;
            }

            let headers = {
                'Authorization': this.state.authorizationValue
            }

            fetch(newUrl, { headers })
                .then((response) => {
                    this.state.httpStatusCode = response.status;
                    if (response.ok) {
                        return response.json()
                    }
                })
                .then(data => {
                    this.setState({
                        surveyResponse: data,
                        loading: false,
                        sharedLinkId: incommingPath.substring(1),
                        css: data && data.css ? data.css : '',
                        requiredQuestionIds: [],
                        answersBySurveyor: []
                    });
                })
                .catch(error => {
                    this.state.httpStatusCode = 400;
                    console.log(error);
                });
        }
    }

    async postAnswersAsync() {
        let url = 'https://yellowfeedbackapi.autoproof.nl/survey/response'
        //let url = 'https://localhost:44350/survey/response'
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(this.state.answersBySurveyor),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.state.authorizationValue
            }
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        let contents;

        switch (this.state.httpStatusCode) {
            case 200:
                contents = this.renderSurveyResponse()
                break;
            case 401:
                contents = this.renderAuthorizationForm()
                break;
            case 400:
                contents = this.renderWrong()
                break;
            default:
                contents = this.renderLoading()
                break;
        }

        return (
            <>
                {contents}
            </>
        );
    }

    renderPageTitle() {
        return (
            <HelmetProvider>
                <Helmet className="App">
                    <title>{(this.state.surveyResponse && this.state.surveyResponse.title) && this.state.surveyResponse.title}</title>
                </Helmet>
            </HelmetProvider>
        );
    }

    renderLoading() {
        return (
            <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
            </div>
        );
    }

    renderWrong() {
        return (
            <section>
                <h1>
                    Oops, something went wrong...
                </h1>
            </section>
        );
    }

    renderSurveyResponse() {
        this._appendCss();

        if (this.state.surveyResponse.tryLimitRepeatResponseForMinutes) {
            let cookie = this._getCookie(this.state.sharedLinkId)
            if (cookie) {
                return (
                    <div
                        className="show-close-message"
                    >
                        {this.renderMessage('Close')}
                    </div>
                )
            }
        }

        let surveyResponse = this.state.surveyResponse;
        this.renderPageTitle();
        this._addRequiredQuestionsToState(surveyResponse.questions);
        let headerContentEmpty = !surveyResponse.imageUri && !surveyResponse.title && !surveyResponse.description;
        let content = headerContentEmpty ? <></> : this.renderHeader(surveyResponse);
        return (
            <>
                {this.renderMessage('Intro')}

                <section id="main" className="main-container">

                    {content}

                    <article className="questions-container">

                        {this.renderSupportedLanguages()}

                        {this.renderQuestions(surveyResponse.questions)}

                        {this.renderSendButton()}

                    </article>

                    <article className="footer-container">
                    </article>

                </section >

                <div
                    className={this.state.activateCloseMessage ? "show-close-message" : "hide-close-message"}
                >
                    {this.renderMessage('Close')}
                </div>
            </>
        )
    }

    renderAuthorizationForm() {
        return (
            <>
                <section
                    className="intro-overlay"
                >

                    <article
                        className="authorization"
                    >

                        <div
                            className="authorization-form-container"
                        >

                            <span>
                                &#128274;
                            </span>

                            <br />

                            <input id="headervalue" type="password" placeholder="Please enter password..." />

                            <button
                                className="active-button"
                                onClick={(e) => { this._handleAuthorization(); }}
                            >
                                &#x2713;
                            </button>

                        </div>

                    </article>

                </section>
            </>
        )
    }

    renderMessage(messageType) {
        if (this.state.activateCloseMessage) {
            this._trySetCookie();
        }

        const image = this.state.surveyResponse.imageUri;
        const intro = this.state.surveyResponse.surveyMessages.find(message => message.messageType === messageType);

        if (!intro && messageType == 'Close') {
            return (
                <section
                    id={messageType}
                    className="intro-overlay"
                >

                    <article
                        className="intro-popup"
                    >

                        <div
                            className="intro-content-container"
                        >

                            <span className="default-icon-message-send">&#x2713;</span>

                        </div>

                    </article>

                </section>
            )
        }

        return (
            <>
                {(intro && intro.surveyMessageContent) &&
                    <section
                        id={messageType}
                        className="intro-overlay"
                    >

                        <article
                            className="intro-popup"
                        >

                            {image &&
                                <div
                                    className="intro-image-container"
                                >

                                    <img
                                        alt=""
                                        src={image}
                                    />

                                </div>
                            }

                            <div
                                className="intro-content-container"
                            >

                                <h2>{intro.surveyMessageContent.title}</h2>

                                <div
                                    className="intro-content"
                                >

                                    {intro.surveyMessageContent.description}

                                </div>

                            </div>

                            {messageType === 'Intro' &&
                                <div
                                    className="intro-button-container"
                                >

                                    <button
                                        onClick={e => { this._removeElementById(messageType) }}
                                    >
                                        &#x2713;
                                    </button>

                                </div>
                            }

                        </article>

                    </section>
                }
            </>
        )
    }

    renderHeader(surveyResponse) {
        let show = surveyResponse.imageUri || surveyResponse.title || surveyResponse.description;
        return (
            <article className={show ? "head-container" : ""}>

                {surveyResponse.imageUri &&
                    <div className="head-container__header-image">
                        <img
                            alt=""
                            src={surveyResponse.imageUri}
                        />
                    </div>
                }

                {surveyResponse.title &&
                    <div className="head-container__title">
                        {surveyResponse.title}
                    </div>
                }

                {surveyResponse.description &&
                    <div className="head-container__description">
                        {surveyResponse.description}
                    </div>
                }

            </article>
        )
    }

    renderQuestions(questions) {
        questions = questions ? questions : [];

        // sort acc by index
        questions.sort(function (a, b) {
            return a.index - b.index;
        });

        let contents = questions.map(question => (

            <article
                id={question.questionId}
                key={question.questionId}
                className={(question.answerId ? "hide_question " : "") + " questions-container__question"}
            >
                {question.answeringQuestionIsRequired && <span id={"required" + question.questionId} className="question-required"></span>}

                <label>{question.question}</label>

                <p> {question.description}</p>

                {question.imageUrl &&
                    <img
                        alt=""
                        src={question.imageUrl}
                    />
                }

                {this.renderAnswers(question)}

            </article>

        ));

        return (
            <React.Fragment>
                {contents}
            </React.Fragment>
        )
    }

    renderAnswers(question) {
        /*
        CheckBox = 0,
        TextBox = 1,
        RadioButton = 3,
        Emoticon = 4,
        StarRate = 5,
        List = 6,
        Date = 7,
        Time = 8,
        NumberRating = 9,
        Numbers = 10,
        DateTime = 11
        */

        let contents =
            (() => {
                switch (question.answerType) {
                    case 0:
                        return this.renderCheckboxes(question.answers, question.answerType, question.questionId)
                    case 1:
                        return this.renderTextbox(question.answers, question.answerType, question.questionId)
                    case 3:
                        return this.renderRadiobuttons(question.answers, question.answerType, question.questionId)
                    case 4:
                        return this.renderMood(question.answers, question.answerType, question.questionId)
                    case 5:
                        return this.renderNumberRating(question.answers, question.answerType, question.questionId)
                    case 6:
                        return this.renderListbox(question.answers, question.answerType, question.questionId)
                    case 7:
                        return this.renderDate(question.answers, question.answerType, question.questionId)
                    case 8:
                        return this.renderTime(question.answers, question.answerType, question.questionId)
                    case 9:
                        return this.renderStarRating(question.answers, question.answerType, question.questionId)
                    case 10:
                        return this.renderNumbersField(question.answers, question.answerType, question.questionId)
                    case 11:
                        return this.renderDateTime(question.answers, question.answerType, question.questionId)
                    default:
                        return null
                }
            })()

        return (
            <section className="answers-container">

                {contents}

            </section>
        )
    }

    renderCheckboxes(answers, answerType, questionId) {
        let contents = answers.map((answer, index) => (
            <React.Fragment key={answer.answerId} >
                <label
                    className="checkbox"
                    htmlFor={answer.answerId}>
                    <input
                        name={answer.questionId}
                        id={answer.answerId}
                        type="checkbox"
                        value={answer.answerId}
                        onClick={(e) => { this._handle(e, answer, answerType, answers); }}
                    />
                    <div className="checkbox-content">
                        <span>{answer.answer}</span>
                    </div>
                </label>
                <br />

                {answer.questions && this.renderQuestions(answer.questions)}

            </React.Fragment>
        ));

        return (
            <article
                id={questionId}
                className={"answers-container__answer"}
            >

                {contents}

            </article>
        )
    }

    renderTextbox(answers, answerType, questionId) {
        let content = answers.map(answer => (
            <React.Fragment
                key={answer.answerId}>

                <textarea
                    id={answer.answerId}
                    onChange={(e) => { this._handle(e, answer, answerType); }}
                >
                </textarea>

                <br />

                {answer.questions && this.renderQuestions(answer.questions)}

            </React.Fragment>
        ));

        return (
            <article
                id={questionId}
                className={"answers-container__answer"}>

                {content}

            </article>
        )
    }

    renderNumbersField(answers, answerType, questionId) {
        let content = answers.map(answer => (
            <React.Fragment
                key={answer.answerId}
            >

                <input
                    id={answer.answerId}
                    type="number"
                    onChange={(e) => { this._handle(e, answer, answerType); }}
                />

                <br />

                {(answer && answer.questions) && this.renderQuestions(answer.questions)}

            </React.Fragment>
        ));

        return (
            <article
                id={questionId}
                className={"answers-container__answer"}>

                {content}

            </article>
        )
    }

    renderRadiobuttons(answers, answerType, questionId) {
        let contents = answers.map(answer => (
            <React.Fragment
                key={answer.answerId} >
                <label
                    className="radiobutton"
                    htmlFor={answer.answerId}>

                    <input
                        name={answer.questionId}
                        id={answer.answerId}
                        type="radio"
                        value={answer.answerId}
                        onClick={(e) => { this._handle(e, answer, answerType, answers); }}
                    />

                    <div className="radiobutton-content">
                        <span>{answer.answer}</span>
                    </div>

                </label>

                <br />

                {answer.questions && this.renderQuestions(answer.questions)}

            </React.Fragment>
        ));

        return (
            <article
                id={questionId}
                className="answers-container__answer"
            >

                {contents}

            </article>
        )
    }

    renderStarRating(answers, answerType, questionId) {
        // sort descending by value
        answers.sort(function (a, b) {
            return b.answerRate - a.answerRate;
        });

        let contents = answers.map((answer) => (
            <React.Fragment
                key={answer.answerId}
            >
                <input
                    id={answer.answerId}
                    type="radio" name="starrating"
                    value={answer.answerRate}
                    onClick={(e) => { this._handle(e, answer, answerType, answers); }}
                />

                <label
                    htmlFor={answer.answerId}
                    title={answer.answer}>

                    <i
                        className="active fa fa-star"
                        aria-hidden="true"
                    >
                    </i>

                </label>

            </React.Fragment>
        ));

        return (
            <>

                <article
                    id={questionId}
                    className={"starrating__container answers-container__answer answers-container__answer-starrating"}>

                    <div className="starrating">
                        {contents}
                    </div>

                </article>

                {
                    answers.map(answer => (
                        <React.Fragment
                            key={answer.answerId}
                        >

                            {answer.questions && this.renderQuestions(answer.questions)}

                        </React.Fragment>
                    ))
                }

            </>
        )
    }

    renderMood(answers, answerType, questionId) {
        // sort by value
        answers.sort(function (a, b) {
            return a.answerRate - b.answerRate;
        });

        let contents = answers.map(answer => (
            <article
                key={answer.questionId + answer.answerId}
                className="answers-container__answer answers-container__answer-mood">

                <label htmlFor={answer.answerId}>

                    <input
                        className="mood"
                        type="radio"
                        name={answer.questionId}
                        id={answer.answerId}
                        value={answer.answerRate}
                        onClick={(e) => { this._handle(e, answer, answerType, answers); }} />

                    {(() => {
                        switch (answer.answerRate) {
                            case 1:
                                return (<span>😡</span>)
                            case 2:
                                return (<span>🙁</span>)
                            case 3:
                                return (<span>😐</span>)
                            case 4:
                                return (<span>😁</span>)
                            case 5:
                                return (<span>😍</span>)
                            default:
                                return null
                        }
                    })()}
                </label>

            </article>
        ));

        return (
            <>

                <article
                    id={questionId}
                    className="mood-container answers-container__answer answers-container__answer-4"
                >

                    {contents}

                </article>

                {
                    answers.map((answer, index) => (
                        <React.Fragment
                            key={answer.answerId + index}
                        >

                            {answer.questions && this.renderQuestions(answer.questions)}

                        </React.Fragment>
                    ))
                }

            </>
        )
    }

    renderDateTime(answers, answerType) {
        let contents = answers.map(answer => (
            <React.Fragment
                key={answer.answerId}
            >

                <input
                    type="datetime-local"
                    id={answer.answerId}
                    onChange={(e) => { this._handle(e, answer, answerType); }}
                />

                <br />

                {answer.questions && this.renderQuestions(answer.questions)}

            </React.Fragment>
        ))

        return (
            <article
                className="answers-container__answer"
            >

                {contents}

            </article>
        )
    }

    renderDate(answers, answerType) {
        let contents = answers.map(answer => (
            <React.Fragment
                key={answer.answerId}
            >

                <input
                    type="date"
                    id={answer.answerId}
                    onChange={(e) => { this._handle(e, answer, answerType); }}
                />

                <br />

                {answer.questions && this.renderQuestions(answer.questions)}

            </React.Fragment>
        ))

        return (
            <article
                className="answers-container__answer"
            >

                {contents}

            </article>
        )
    }

    renderTime(answers, answerType) {
        let contents = answers.map(answer => (
            <React.Fragment
                key={answer.answerId}
            >

                <input
                    type="time"
                    id={answer.answerId}
                    onChange={(e) => { this._handle(e, answer, answerType); }}
                />

                <br />

                {answer.questions && this.renderQuestions(answer.questions)}

            </React.Fragment>
        ))

        return (
            <article
                className="answers-container__answer"
            >

                {contents}

            </article>
        )
    }

    renderListbox(answers, answerType, questionId) {
        let contents = answers.map(answer => (
            <React.Fragment
                key={answer.answerId}
            >

                <option
                    value={answer.answerId}
                >
                    {answer.answer}
                </option>

            </React.Fragment>
        ))

        return (
            <>

                <article
                    id={questionId}
                    className="answers-container__answer"
                >

                    <select
                        onChange={(e) => { this._handle(e, null, answerType, answers); }}
                    >

                        <option>             </option>

                        {contents}
                    </select>

                </article>

                {
                    answers.map((answer, index) => (
                        <React.Fragment
                            key={answer.answerId + index}
                        >

                            {answer.questions && this.renderQuestions(answer.questions)}

                        </React.Fragment>
                    ))
                }

            </>
        )
    }

    renderNumberRating(answers, answerType) {
        // sort descending by value
        answers.sort(function (a, b) {
            return a.answerRate - b.answerRate;
        });

        let contents = answers.map(answer => (
            <React.Fragment key={answer.answerId} >
                <label className="numberrating"
                    htmlFor={answer.answerId}
                >
                    <input
                        name={answer.questionId}
                        id={answer.answerId}
                        type="radio"
                        value={answer.answerId}
                        onClick={(e) => { this._handle(e, answer, answerType, answers); }}
                    />
                    <div className="numberrating-content">
                        <span>{answer.answerRate}</span>
                    </div>
                </label>
            </React.Fragment>
        ));

        return (
            <>
                <article
                    id={answers[0].questionId}
                    className={"numberrating-container answers-container__answer answers-container__answer-starrating"}>

                    {contents}

                </article>

                {
                    answers.map((answer, index) => (
                        <React.Fragment
                            key={answer.answerId + index}
                        >

                            {answer.questions && this.renderQuestions(answer.questions)}

                        </React.Fragment>
                    ))
                }

            </>
        )
    }

    renderSendButton() {
        let contents = (
            <React.Fragment>
                <button
                    className={this.state.activateSendButton ? "active-button" : "disable-button"}
                    onClick={() => { this._handleSendButton() }}
                >
                    &#10148;
                </button>

            </React.Fragment>
        );

        return (
            <article className="send-button">

                {contents}

            </article>
        )
    }

    renderSupportedLanguages() {
        let supportedLanguages = this.state.surveyResponse.supportedLanguages;
        let currentLanguage = supportedLanguages.find(sl => sl.cultureInfoCode === this.state.surveyResponse.cultureInfoCode);

        let contents = supportedLanguages.filter(sl => sl.cultureInfoCode !== currentLanguage.cultureInfoCode).map(language => (
            <React.Fragment
                key={language.cultureInfoCode}
            >

                <option
                    value={language.cultureInfoCode}
                >
                    {language.language}
                </option>

            </React.Fragment>
        ))

        return (
            <article
                className="supported-language-container"
            >

                <select
                    onChange={(e) => { this._hanleSupportedLanguage(e); }}
                >

                    <option
                        value={currentLanguage.cultureInfoCode}
                    >

                        {currentLanguage.language}

                    </option>

                    {contents}

                </select>

            </article>
        )
    }

    _appendCss() {
        if (this.state.css) {
            const style = document.createElement('style');
            style.textContent = this.state.css;
            document.head.appendChild(style);
        }
    }

    _removeElementById(id) {
        let element = document.getElementById(id);

        if (element) {
            element.remove()
        }
    }

    _handleAuthorization(e) {
        let headerValue = document.getElementById("headervalue").value;
        this.state.loading = true;
        this.state.authorizationValue = headerValue;
        this.populateSurveyResponse();
    }

    _handle(e, answer, answerType, answers) {
        switch (answerType) {
            case 0:
                return this._handleCheckbox(e, answer, answerType)
            case 1:
                return this._handleTextbox(e, answer, answerType)
            case 3:
                return this._handleRadiobutton(e, answer, answers, answerType)
            case 4:
                return this._handleMood(e, answer, answers, answerType)
            case 5:
                return this._handleNumberRating(e, answer, answers, answerType)
            case 6:
                return this._handleListbox(e, answers, answerType)
            case 7:
                return this._handleDate(e, answer, answerType)
            case 8:
                return this._handleTime(e, answer, answerType)
            case 9:
                return this._handleStarRating(e, answer, answers, answerType)
            case 10:
                return this._handleNumbers(e, answer, answerType)
            case 11:
                return this._handleDateTime(e, answer, answerType)
            default:
                return null
        }
    }

    _handleCheckbox(e, answer, answerType) {
        if (e.target.checked) {
            this._addAnswerBySurveyor(answer, answerType);
            this._showRelatedQuestions(answer);
            this._removeAsteriskSymbolForRequired(answer.questionId);

        } else {
            this._hideRelatedQuestions(answer);
            this._removeAnsweredQuestionsByAnswer(answer);

            if (!this._answersBySurveyorExistsByQuestionId(answer.questionId)) {
                this._addAsteriskSymbolForRequired(answer.questionId);
            }
        }
    }

    _handleTextbox(e, answer, answerType) {
        if (e.target.value) {
            if (this._sameExists(answer)) {
                if (!this._removedWhenOnlyValueDiffers(e.target.value, answer)) {
                    return;
                }
            }

            answer.answer = e.target.value;
            this._addAnswerBySurveyor(answer, answerType);
            this._showRelatedQuestions(answer);
            this._removeAsteriskSymbolForRequired(answer.questionId);
        } else {
            this._hideRelatedQuestions(answer);
            this._removeAnsweredQuestionsByAnswer(answer);
            this._addAsteriskSymbolForRequired(answer.questionId);
        }
    }

    _handleNumbers(e, answer, answerType) {
        if (e.target.value) {
            if (this._sameExists(answer)) {
                if (!this._removedWhenOnlyValueDiffers(e.target.value, answer)) {
                    return;
                }
            }
            //todo: verify if number

            answer.answer = e.target.value;
            this._addAnswerBySurveyor(answer, answerType);
            this._showRelatedQuestions(answer);
            this._removeAsteriskSymbolForRequired(answer.questionId);
        } else {
            this._hideRelatedQuestions(answer);
            this._removeAnsweredQuestionsByAnswer(answer);
            this._addAsteriskSymbolForRequired(answer.questionId);
        }
    }

    _handleRadiobutton(e, answer, answers, answerType) {
        if (e.target.checked) {
            if (!answer) {
                return;
            }

            if (this._sameExists(answer)) {
                e.target.checked = false;
                this._hideRelatedQuestions(answer);
                this._removeAnsweredQuestionsByAnswer(answer);
                this._addAsteriskSymbolForRequired(answer.questionId);

                return;
            }

            //remove all reasons elements
            answers.map(answer => {
                this._hideRelatedQuestions(answer);
            });

            this._removeAnsweredQuestionsByQuestionId(answer.questionId);
            this._addAnswerBySurveyor(answer, answerType);
            this._showRelatedQuestions(answer);
            this._removeAsteriskSymbolForRequired(answer.questionId);
        } else {
            this._hideRelatedQuestions(answer);
            this._removeAnsweredQuestionsByAnswer(answer);
        }
    }

    _handleStarRating(e, answer, answers, answerType) {
        if (e.target.checked) {
            if (!answer) {
                return;
            }

            if (this._sameExists(answer)) {
                e.target.checked = false;
                this._hideRelatedQuestions(answer);
                this._removeAnsweredQuestionsByAnswer(answer);
                this._addAsteriskSymbolForRequired(answer.questionId);

                return;
            }

            //remove all reasons elements
            answers.map(answer => (
                this._hideRelatedQuestions(answer)
            ));

            this._removeAnsweredQuestionsByQuestionId(answer.questionId);
            this._addAnswerBySurveyor(answer, answerType);
            this._showRelatedQuestions(answer);
            this._removeAsteriskSymbolForRequired(answer.questionId);
        } else {
            this._hideRelatedQuestions(answer);
            this._removeAnsweredQuestionsByAnswer(answer);
        }
    }

    _handleMood(e, answer, answers, answerType) {
        if (e.target.checked) {
            if (!answer) {
                return;
            }

            if (this._sameExists(answer)) {
                e.target.checked = false;
                this._hideRelatedQuestions(answer);
                this._removeAnsweredQuestionsByAnswer(answer);
                this._addAsteriskSymbolForRequired(answer.questionId);

                return;
            }

            //remove all reasons elements
            answers.map(answer => (
                this._hideRelatedQuestions(answer)
            ));

            this._removeAnsweredQuestionsByQuestionId(answer.questionId);
            this._addAnswerBySurveyor(answer, answerType);
            this._showRelatedQuestions(answer);
            this._removeAsteriskSymbolForRequired(answer.questionId);
        } else {
            this._hideRelatedQuestions(answer);
            this._removeAnsweredQuestionsByAnswer(answer);
        }
    }

    _handleDateTime(e, answer, answerType) {
        if (e.target.value) {
            if (this._sameExists(answer)) {
                if (!this._removedWhenOnlyValueDiffers(e.target.value, answer)) {
                    return;
                }
            }

            answer.answer = e.target.value;
            this._addAnswerBySurveyor(answer, answerType);
            this._showRelatedQuestions(answer);
            this._removeAsteriskSymbolForRequired(answer.questionId);
        } else {
            this._hideRelatedQuestions(answer);
            this._removeAnsweredQuestionsByAnswer(answer);
            this._addAsteriskSymbolForRequired(answer.questionId);
        }
    }

    _handleDate(e, answer, answerType) {
        if (e.target.value) {
            if (this._sameExists(answer)) {
                if (!this._removedWhenOnlyValueDiffers(e.target.value, answer)) {
                    return;
                }
            }

            answer.answer = e.target.value;
            this._addAnswerBySurveyor(answer, answerType);
            this._showRelatedQuestions(answer);
            this._removeAsteriskSymbolForRequired(answer.questionId);
        } else {
            this._hideRelatedQuestions(answer);
            this._removeAnsweredQuestionsByAnswer(answer);
            this._addAsteriskSymbolForRequired(answer.questionId);
        }
    }

    _handleTime(e, answer, answerType) {
        if (e.target.value) {
            if (this._sameExists(answer)) {
                if (!this._removedWhenOnlyValueDiffers(e.target.value, answer)) {
                    return;
                }
            }

            answer.answer = e.target.value;
            this._addAnswerBySurveyor(answer, answerType);
            this._showRelatedQuestions(answer);
            this._removeAsteriskSymbolForRequired(answer.questionId);
        } else {
            this._hideRelatedQuestions(answer);
            this._removeAnsweredQuestionsByAnswer(answer);
            this._addAsteriskSymbolForRequired(answer.questionId);
        }
    }

    _handleListbox(e, answers, answerType) {
        if (e.target.value && e.target.selectedIndex > 0) {
            let answer = answers.find(a => a.answerId === e.target.value);

            if (!answer || this._sameExists(answer)) {

                return;
            }

            //remove all reasons elements
            answers.map(answer => {
                this._hideRelatedQuestions(answer);

                //todo check of deze klopt:
                this._removeAnsweredQuestionsByAnswer(answer);
                this._addAsteriskSymbolForRequired(answer.questionId);
            });

            this._removeAnsweredQuestionsByQuestionId(answer.questionId);
            this._addAnswerBySurveyor(answer, answerType);
            this._showRelatedQuestions(answer);
            this._removeAsteriskSymbolForRequired(answer.questionId);
        } else {
            answers.map(answer => {
                this._hideRelatedQuestions(answer);
                this._removeAnsweredQuestionsByAnswer(answer);
                this._addAsteriskSymbolForRequired(answer.questionId);
            });
        }
    }

    _handleNumberRating(e, answer, answers, answerType) {
        if (e.target.checked) {
            if (!answer) {
                return;
            }

            if (this._sameExists(answer)) {
                e.target.checked = false;
                this._hideRelatedQuestions(answer);
                this._removeAnsweredQuestionsByAnswer(answer);
                this._addAsteriskSymbolForRequired(answer.questionId);

                return;
            }

            //remove all reasons elements
            answers.map(answer => (
                this._hideRelatedQuestions(answer)
            ));

            this._removeAnsweredQuestionsByQuestionId(answer.questionId);
            this._addAnswerBySurveyor(answer, answerType);
            this._showRelatedQuestions(answer);
            this._removeAsteriskSymbolForRequired(answer.questionId);
        } else {
            this._hideRelatedQuestions(answer);
            this._removeAnsweredQuestionsByAnswer(answer);
        }
    }

    _handleSendButton() {
        this.setState({ activateSendButton: false });
        this.setState({ activateCloseMessage: true });
        this._removeElementById('main');
        this.postAnswersAsync();
    }

    _hanleSupportedLanguage(e) {
        if (e.target.value && (e.target.value !== this.state.surveyResponse.cultureInfoCode)) {
            this.state.loading = true;
            let path = window.location.pathname;
            let url = window.location.protocol + '//' + window.location.host + path + `?language=${e.target.value}`;
            window.location.replace(url);
        }
    }


    _addRequiredQuestionsToState(questions) {
        if (questions && questions.length > 0) {
            questions.map(question => {
                if (question && question.answeringQuestionIsRequired) {
                    if (!this.state.requiredQuestionIds.find(id => id === question.questionId)) {
                        this.state.requiredQuestionIds.push(question.questionId);
                    }
                }
            });
        }
    }

    _removeRequiredQuestionsFromState(questions) {
        if (questions) {
            questions.map(q => {
                this._removeAnsweredQuestionsByQuestionId(q.questionId);

                if (q.answeringQuestionIsRequired) {
                    this.state.requiredQuestionIds = this.state.requiredQuestionIds.filter(function (id) {
                        return id !== q.questionId
                    });
                }
            });
        }
    }

    _showRelatedQuestions(answer) {
        if (answer && answer.questions && answer.questions.length > 0) {
            answer.questions.map(q => {
                let element = document.getElementById(q.questionId);
                if (element) {
                    element.classList.remove("hide_question");
                    element.classList.add("show_question");
                }
            });
        }
    }

    _hideRelatedQuestions(answer) {
        if (answer && answer.questions && answer.questions.length > 0) {
            this._removeRequiredQuestionsFromState(answer.questions);
            answer.questions.map(q => {
                let element = document.getElementById(q.questionId);
                if (element) {
                    element.classList.remove("show_question");
                    element.classList.add("hide_question");
                }

                this._removeAnsweredQuestionsByQuestionId(q.questionId);

                q.answers.map(a => {
                    this._hideRelatedQuestions(a);
                });
            });

            this._clearRelatedValues(answer.questions);
            this._clearRelatedValuesFromResponses(answer.questions);
        }
    }

    _addAnswerBySurveyor(answer, answerType) {
        if (!answer) {
            return;
        }

        let answerBySurveyor = {
            questionId: answer.questionId,
            groupId: answer.questionId,
            answerId: answer.answerId,
            answerValue: answer.answer,
            sharedLinkId: this.state.sharedLinkId,
            answerRate: answer.answerRate,
            surveyorScreenWidth: window.screen.width,
            surveyorScreenHeight: window.screen.height,
            cultureInfoCode: this.state.surveyResponse.cultureInfoCode,
            answerType: answerType,
            answeredDateTime: new Date().toISOString(),
            userAgent: navigator.userAgent,
            appVersion: navigator.appVersion
        };

        this.state.answersBySurveyor.push(answerBySurveyor);
        this._addRequiredQuestionsToState(answer.questions);
        this._verifyRequiredQuestionsAreAnswered();
    }

    _removeAnsweredQuestionsByAnswer(answer) {
        if (!answer) {
            return;
        }

        this._removeRequiredQuestionsFromState(answer.questions);
        this.state.answersBySurveyor = this.state.answersBySurveyor.filter(function (a) {
            return a.answerId !== answer.answerId
        });
        this._verifyRequiredQuestionsAreAnswered();
    }

    _removeAnsweredQuestionsByQuestionId(questionId) {
        if (!questionId) {
            return;
        }

        this.state.answersBySurveyor = this.state.answersBySurveyor.filter(function (a) {
            return a.questionId !== questionId
        });

        this._verifyRequiredQuestionsAreAnswered();
    }

    _sameExists(answer) {
        let existingAnswers = this.state.answersBySurveyor.filter(function (a) {
            return a.answerId === answer.answerId
        });

        if (existingAnswers && existingAnswers.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    _answersBySurveyorExistsByQuestionId(questionId) {
        let existingAnswers = this.state.answersBySurveyor.filter(function (a) {
            return a.questionId === questionId
        });

        if (existingAnswers && existingAnswers.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    _removedWhenOnlyValueDiffers(value, answer) {
        let sameExists = false;
        this.state.answersBySurveyor.map(a => {
            if (a.answerId === answer.answerId && a.answerValue !== value) {
                this._removeAnsweredQuestionsByAnswer(a);
                sameExists = true;
            }
        });

        return sameExists;
    }

    _clearRelatedValues(questions) {
        if (!questions) {
            return;
        }

        questions.map(q => {
            if (q.answers) {
                q.answers.map(a => {
                    this._clearElement(a.answerId, q.answerType)
                    this._clearRelatedValues(a.questions);
                })
            }
        });
    }

    _clearElement(answerId, answerType) {
        switch (answerType) {
            case 0:
            case 3:
            case 4:
            case 5:
            case 9:
            case 10:
                let check = document.getElementById(answerId);
                if (check) {
                    check.checked = false;
                }
                break;
            case 1:
            case 6:
            case 7:
            case 8:
            case 11:
                let element = document.getElementById(answerId);
                if (element) {
                    element.value = '';
                }
                break;
            default:
                return null
        }
    }

    _clearRelatedValuesFromResponses(questions) {
        if (!questions) {
            return;
        }

        questions.map(q => {
            if (q.answers) {
                q.answers.map(a => (
                    this._removeAnsweredQuestionsByAnswer(a)
                ))
            }
        });
    }

    _removeAsteriskSymbolForRequired(questionId) {
        let element = document.getElementById(`required${questionId}`);

        if (element) {
            element.className = '';
        }
    }

    _addAsteriskSymbolForRequired(questionId) {
        let element = document.getElementById(`required${questionId}`);

        if (element) {
            element.classList.add('question-required');
        }
    }

    _verifyRequiredQuestionsAreAnswered() {
        let ok = true;

        this.state.requiredQuestionIds.map(questionId => {
            if (ok) {
                let result = this.state.answersBySurveyor.find(answer => answer.questionId === questionId);
                if (!result) {
                    ok = false;
                }
            }
        });

        if (ok) {
            this.setState({ activateSendButton: true });
        } else {
            this.setState({ activateSendButton: false });
        }
    }

    _getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    _trySetCookie() {
        if (this.state.surveyResponse.tryLimitRepeatResponseForMinutes) {
            var currentDate = new Date();
            var futureDate = new Date(currentDate.getTime() + this.state.surveyResponse.tryLimitRepeatResponseForMinutes * 60000);
            document.cookie = `${this.state.sharedLinkId}=${this.state.surveyResponse.cultureInfoCode};expires=${futureDate.toUTCString()}`;
        }
    }
}